import styled from "styled-components"
import {
  TitleBig,
  Subtitle as Sub,
  BodyText,
  SmlText,
} from "src/atoms/typography"
import { MenuText } from "../../../atoms/typography"

export const Container = styled.div`
  width: 50%;
   max-height: 100vh; 

  @media (max-width: 768px) {
    width: 75%;
    margin: 3rem auto;
    max-height: 200vh; 

  }
`

export const Subtitle = styled(SmlText)`
  text-transform: uppercase;
  font-size: 8rem;
  font-weight: 900;
  @media (max-width: 768px) {
    font-size: 4rem;
    text-align: right;

  }


`

export const Title = styled(SmlText)`
font-size: 5rem;
font-weight: 400;
text-align: right;
@media (max-width: 768px) {
  font-size: 2.5rem;

}
`

export const Date = styled(MenuText)`

font-size: 3rem;
font-weight: 700;
font-style: italic;
text-align: right;
@media (max-width: 768px) {
  font-size: 1.5rem;
  letter-spacing: normal;

}
`

export const Description = styled(BodyText)`
  margin-top: 2.5rem;
  overflow: hidden;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
  @media (min-width: 769px) {
    max-height: 200vh !important;
  
  }
`

export const SeeMore = styled.button`
margin: 1rem auto;
    display: block;
    border: none;
    background-color: lightgray;
    font-family: "Montserrat",sans-serif;
    font-weight: 500;
    font-size: 1rem;
    background-color: #f93737;
    color: #e9d1bb;
    letter-spacing: 2px;
    text-transform: uppercase;
    margin-bottom: 2rem;
    @media (min-width: 769px) {
      display:none;
    
    }

`
