import React from "react"
import TitlePageLanding from "src/components/TitlePageLanding"
import { AnimatePresence, motion } from "framer-motion"

const Landing = () => {
  return (
    <AnimatePresence>
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", width: "100%" }}
        >
          <TitlePageLanding
            title={"Krzysztof Kamil"}
            subtitle={"Baczyński"}
            date={"1921-1944"}
           
          />
        </div>
      </motion.div>
    </AnimatePresence>
  )
}

export default Landing
