import React, {useState} from "react"
import { Container, Title, Subtitle, Date, Description, SeeMore } from "./styles";
import parse from "html-react-parser"
import { useArticles } from "src/hooks"


const TitlePageLanding = ({
  title = "",
  subtitle = "",
  date = "",
}) => {
  const articles = useArticles({ articleCategory: 9066 })
  const { list } = articles
  const [descExtend, setDescExtend] = useState(false);
  return (
    <Container>
      <Title>{title}</Title>
      <Subtitle>{subtitle}</Subtitle>
      <Date>{date}</Date>
      <Description style={{'maxHeight': `${descExtend? '200vh':'12vh'}`}}>{list[2]?.Content  && parse(list[2].Content)}</Description>
      <SeeMore onClick={()=>{setDescExtend(!descExtend)}}>{descExtend? 'mniej':'więcej'}</SeeMore>
    </Container>
      
  )
}

export default TitlePageLanding
