import { navigate } from "gatsby"
import React, { useEffect } from "react"
import SEO from "../components/seo"
import Landing from "src/views/Landing"

const LandingPage = () => {
  return (
    <>
      <SEO
        title="Krzysztof Kamil Baczyński 1921-1944"
        description="Multimedialna opowieść o życiu Krzysztofa Kamila Baczyńskiego, jednego z najwybitniejszych poetów pokolenia Kolumbów."
        image={require('images/seo/1.jpg')}
      />
      <Landing></Landing>
    </>
  )
}

export default LandingPage
